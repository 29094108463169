<template>
  <div class="justify-center full-height" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl justify-between items-end">
        <div class="text-h4">Links</div>
        <MediaSelect class="col-3" v-bind:selection.sync="selection" label="Showing Links for:" v-if="$store.getters['auth/homePageFilter']" />
      </div>

      <div class="row q-gutter-xl justify-between">
        <q-btn @click="showModal = true" color="primary">Add New Link</q-btn>
      </div>

      <div v-if="filteredLinks.length" class="q-my-xl">
        <q-list>
          <draggable handle=".drag-handle-item" v-model="filteredLinks">
            <q-item v-for="link in filteredLinks" :key="link.uid" class="q-my-md">
              <q-item-section avatar class="col-1 drag-handle-item cursor-pointer">
                <q-icon name="drag_indicator"/>
              </q-item-section>
              <q-item-section class="col-3 drag-handle-item cursor-pointer">
                <img :src="link.path" style="max-width: 168px; height: 95px;">
              </q-item-section>
              <q-item-section class="col-5">
                <q-item-label>{{link.settings.title}}</q-item-label>
              </q-item-section>
              <q-item-section class="col-1">
                <q-btn flat @click="selectForEdit(link)">Edit</q-btn>
              </q-item-section>
              <q-item-section class="col-1">
                <q-btn flat @click="confirmDelete(link)" :loading="deleting === link.uid">Delete</q-btn>
              </q-item-section>
            </q-item>
          </draggable>
        </q-list>
      </div>
      <div v-else class="q-my-xl col-xs-12">
        <div align="center">There are currently no links for this Location or User Group.</div>
      </div>

      <q-dialog v-model="showModal" @hide="resetForm">
        <q-card style="width: 560px;" class="bg-lmsBackground primaryText-text">
          <q-form @submit="mode === 'create' ? addLink() : saveLink()" @reset="showModal = false" autocomplete="off">
          <q-card-section>
            <div class="text-h5">Create Link</div>
          </q-card-section>
          <q-card-section>
            <div class="col-xs-12 q-mb-lg">
              <h6 class="custom-header-color q-mt-none q-mb-md">Select an image <span class="text-caption">(Actual Size: 168x95)</span></h6>
              <input id="imageAddLinkUploader" @change="showCropper()" class="q-pt-xs full-width q-input-darktheme q-input-inner-styles border-radius-5" type="file" accept="image/*">
              <div v-if="link.path && !imageIsCropped" class="row justify-center q-mt-lg">
                <div style="width: 168px">
                  <q-img :src="link.path">
                    <div v-if="link.settings.overlayTitle" class="absolute-full text-subtitle1 text-weight-medium flex flex-center">
                      {{ link.settings.title }}
                    </div>
                  </q-img>
                </div>
              </div>
              <div v-if="imageIsCropped" class="row justify-center q-mt-lg">
                <div style="width: 168px">
                  <q-img :src="this.imagePreview">
                    <div v-if="link.settings.overlayTitle" class="absolute-full text-subtitle1 text-weight-medium flex flex-center">
                      {{ link.settings.title }}
                    </div>
                  </q-img>
                </div>
                <div class="text-caption col-12 q-mt-sm text-center">Link Preview</div>
              </div>
            </div>
            <div class="row q-mb-lg">
              <div class="col-12 row justify-center" id="uploaderAddImageLinkCropperContainer">
                <img ref="editAddImageLink" id="uploadedAddImageLinkCropper" src="" />
              </div>
            </div>
            <div v-if="showCropButton" class="row justify-center">
              <q-btn v-if="showCropButton" class="q-my-md" @click="cropImageToBlob" color="primary">Crop</q-btn>
            </div>

            <div class="col-xs-12 q-mb-lg">
              <q-input v-model="link.settings.title" label="Title" type="text" :rules="formRules.title" />
            </div>
            <div class="col-xs-12 q-mb-lg">
              <q-select map-options emit-value label="Link Option" v-model="link.linkOption" :options="linkOptions" popup-content-class="bg-lmsBackground primaryText-text" />
            </div>
            <div class="col-xs-12 q-mb-lg" v-if="link.linkOption === 'custom'">
              <q-input id="customLink" placeholder="https://www.google.com/" hint="Please include full URL - https://www.google.com" v-model.trim="link.linkValue" class="q-input-darktheme q-input-inner-styles border-radius-5" type="url" :rules="formRules.custom" />
            </div>
            <div class="col-xs-12 q-mb-lg" v-if="link.linkOption === 'page'">
              <q-select map-options emit-value filter label="Choose LMS Page" v-model="link.linkValue" :options="lmsPageLinkOptions" popup-content-class="bg-lmsBackground primaryText-text" :rules="formRules.page" />
            </div>
            <div class="col-xs-12 q-mb-lg" v-if="link.linkOption === 'module'">
              <filter-select :options="modulesList" v-bind:selection.sync="link.linkValue" label="Choose Module" :thumbnail="true" :rules="formRules.module" />
            </div>
            <div class="col-xs-12 q-mb-lg" v-if="$store.getters['auth/homePageFilter']">
              <MediaSelect v-bind:selection.sync="link.assigned_uid" class="full-width" label="Assign To" />
            </div>
            <div class="col-xs-12 q-mb-lg">
              <q-checkbox v-model="link.settings.overlayTitle" label="Overlay Title"/>
            </div>
          </q-card-section>
          <div class="row gutter-xs q-my-lg q-mx-lg">
            <div class="col-12">
              <q-btn type="submit" class="float-right" :loading="saving" :disable="showCropButton" color="primary">{{ mode === 'create' ? 'Add' : 'Save' }}</q-btn>
              <q-btn type="reset" flat class="float-right on-left">Cancel</q-btn>
            </div>
          </div>
          </q-form>
        </q-card>
      </q-dialog>

    </div>
  </div>
</template>
<script>
import filterSelect from 'components/filterSelect'
import draggable from 'vuedraggable'
import Cropper from 'cropperjs'
import MediaSelect from 'components/MediaSelect'

export default {
  components: { draggable, filterSelect, MediaSelect },
  data () {
    return {
      mode: 'create',
      showModal: false,
      showCropButton: false,
      imageIsCropped: false,
      imagePreview: null,
      saving: false,
      deleting: false,
      link: {
        linkOption: 'page',
        linkValue: 'my-training',
        settings: { overlayTitle: false, title: '' },
        display_order: 1,
        assigned_uid: 'Company'
      },

      linkOptions: [
        { label: 'LMS Page', value: 'page' },
        { label: 'Module', value: 'module' },
        { label: 'Custom URL', value: 'custom' }
      ],
      lmsPageLinkOptions: [
        { label: 'Required Training', value: 'my-training' },
        { label: 'Browse Modules', value: 'browse' },
        { label: 'Bookmarks', value: 'bookmarks' },
        { label: 'Leader Boards', value: 'leaderboards' },
        { label: 'Profile', value: 'profile' }
      ],
      moduleOptions: [],
      moduleLinkValue: null,
      selection: null
    }
  },
  computed: {
    formRules () {
      return {
        title: [ this.$formRules.required('Title'), this.$formRules.maximum(10) ],
        page: [ this.$formRules.required('LMS Page') ],
        module: [ this.$formRules.required('Module') ],
        custom: [ this.$formRules.required('URL') ]
      }
    },
    modulesList () {
      return this.moduleOptions.map(function (module) {
        return {
          value: module.uid,
          label: module.title,
          thumbnail_path: module.thumbnail_path
        }
      })
    },
    filteredLinks: {
      get () {
        return this.$store.getters['links/getByAssignedUid'](this.selection)
      },
      set (data) {
        _.forEach(data, (link, i) => {
          if (!_.isEmpty(this.filteredLinks[i])) link.display_order = i + 1
        })
        this.$store.commit('links/update', data)
        this.$store.dispatch('links/order', data)
      }
    }
  },
  watch: {
    'link.linkOption' () {
      if (this.link.linkOption === 'module') {
        this.getModules()

        // checking for create or else this would override the module on edit
        if (this.mode === 'create') {
          this.link.linkValue = null
        }
      }

      if (this.link.linkOption === 'custom') {
        this.link.linkValue = 'https://lms.msdist.com'
      }
    }
  },
  methods: {
    getModules () {
      this.$moduleSearch.clearCache()
      this.$moduleSearch.search([
        {
          indexName: this.$consts.MODULES_INDEX,
          query: '',
          params: {
            hitsPerPage: 1000,
            filters: `customer_uid:${this.$store.getters['auth/user'].company.uid}`
          }
        }
      ], (err, content) => {
        if (err) throw err
        this.moduleOptions = content.results['0'].hits
      })
    },
    selectForEdit ({ ...pLink }) {
      this.mode = 'edit'
      this.link = pLink
      this.link.settings = { ...pLink.settings }
      this.link.linkOption = this.link.link.split('>')[0]
      this.link.linkValue = this.link.link.split('>')[1]
      this.showModal = true
    },
    showCropper () {
      this.showCropButton = true
      document.querySelector('#uploadedAddImageLinkCropper').src = URL.createObjectURL(document.querySelector('#imageAddLinkUploader').files[0])
      document.querySelector('#uploaderAddImageLinkCropperContainer').style.height = (window.outerHeight * 0.4) + 'px'

      if (this.$refs.editAddImageLink) {
        if (this.cropperAddLink) this.cropperAddLink.destroy()
        this.cropperAddLink = new Cropper(this.$refs.editAddImageLink, {
          aspectRatio: 16 / 9,
          viewMode: 2,
          responsive: true,
          autoCropArea: 1,
          dragMode: 'move'
        })
      }
    },
    cropImageToBlob () {
      let blobLink = this.cropperAddLink.getCroppedCanvas()
      let isIE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent) && !!(document.uniqueID || document.documentMode || window.ActiveXObject || window.MSInputMethodContext)
      if (isIE) {
        this.link.file = blobLink.msToBlob()
        this.imageIsCropped = true
        this.showImagePreview()
        this.showCropButton = false
      } else {
        blobLink.toBlob((blobLink) => {
          this.link.file = blobLink
          this.showImagePreview()
          this.showCropButton = false
        }, 'image/jpeg', 1)
      }
    },
    showImagePreview () {
      let reader = new FileReader()
      reader.readAsDataURL(this.link.file)
      reader.onloadend = () => {
        this.imagePreview = reader.result
        this.imageIsCropped = true
        document.getElementById('uploadedAddImageLinkCropper').src = ''
        document.getElementById('uploaderAddImageLinkCropperContainer').style.height = '0px'
        this.cropperAddLink.destroy()
      }
    },
    confirmDelete (link) {
      this.$q.dialog({
        title: 'Confirm Delete',
        message: `Are you sure you want to permantently delete '${link.settings.title}'?`,
        ok: 'Delete',
        cancel: 'Cancel'
      }).onOk(() => {
        this.deleting = link.uid
        this.$store.dispatch('links/remove', link.uid).then(() => {
          this.deleting = null
          this.$successMsg()
        }).catch((e) => {
          this.deleting = null
          this.$failureMsg()
        })
      })
    },
    formattedLinkObject () {
      return {
        uid: this.link.uid,
        link: `${this.link.linkOption}>${this.link.linkValue}`,
        file: this.link.file,
        assigned_uid: this.link.assigned_uid,
        settings: this.link.settings,
        display_order: this.link.display_order
      }
    },
    addLink () {
      if (!document.getElementById('imageAddLinkUploader').value) {
        this.$failedValidationMsg('Please provide an image')
        return
      }
      this.$q.loading.show()
      this.$store.dispatch('links/add', this.formattedLinkObject()).then((response) => {
        this.$q.loading.hide()
        this.resetForm()
        this.$successMsg()
      }).catch(() => {
        this.saving = false
        this.$q.loading.hide()
        this.$failureMsg()
      })
    },
    saveLink () {
      this.$q.loading.show()

      this.$store.dispatch('links/update', this.formattedLinkObject()).then((response) => {
        this.$q.loading.hide()
        this.resetForm()
        this.$successMsg()
      }).catch(() => {
        this.saving = false
        this.$q.loading.hide()
        this.$failureMsg()
      })
    },
    resetForm () {
      if (this.showModal) {
        document.getElementById('imageAddLinkUploader').value = null
        document.getElementById('uploadedAddImageLinkCropper').src = ''
        document.getElementById('uploaderAddImageLinkCropperContainer').style.height = '0px'
        this.showModal = false
      }
      this.saving = false
      this.showCropButton = false
      this.imageIsCropped = false
      this.uploadedAddImageLink = null
      this.moduleLinkValue = null

      this.link = {
        linkOption: 'page',
        linkValue: 'my-training',
        settings: { overlayTitle: false, title: '' },
        display_order: 1,
        assigned_uid: this.$isAllowed(['||', 'master-admin', 'site-admin'], true) ? 'Company' : this.$store.getters['auth/customer']
      }
      this.mode = 'create'
    }
  },
  created () {
    let cropperCSS = document.createElement('link')
    cropperCSS.setAttribute('href', 'https://cdn.jsdelivr.net/npm/cropperjs/dist/cropper.css')
    cropperCSS.setAttribute('rel', 'stylesheet')
    document.head.appendChild(cropperCSS)

    this.$store.dispatch('links/fetch')
    this.selection = this.$isAllowed(['||', 'master-admin', 'site-admin'], true) ? 'Company' : this.$store.getters['auth/customer']
    this.link.assigned_uid = this.$isAllowed(['||', 'master-admin', 'site-admin'], true) ? 'Company' : this.$store.getters['auth/customer']
  }
}
</script>
